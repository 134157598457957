<script>
import errorsMixin from '@/mixins/errors.mixin'
export default {
  mixins: [errorsMixin],
  props: {
    formErrors: Object
  },

  render() {
    return this.$scopedSlots.default({
      checkValidatorBeforeSend: this.checkValidatorBeforeSend,
      updateShowing: this.updateShowing,
      formData: this.formData,
      send: this.send
    })
  },

  inject: ['svc'],

  data:() => ({
    processing: false,
    formData: {
      city: '',
      date: '',
      notes: '',
      isVisibleOnLanding: true
    },
    course: null
  }),

  methods: {
    checkValidatorBeforeSend(payload) {
      if(!this.formErrors.isValid()) {
        this.$notify({text:'Invalid fields', type: 'error'})
        return
      }
      this.send(payload)
    },

    async send({data, examData}) {
      try {
        this.processing = true
        if(!this.course) await this.sendCourse(data)
        await this.sendExam(this.course.id, examData)
        this.$emit('end')
      } catch (error) {
        console.log(error)
        this.processError(error, {redirectName: this.$ROUTER_NAMES.LOGIN_CRM})
      }
      finally {
        this.processing = false
      }
    },

    async sendCourse(courseData) {
      try {
        let res = await this.svc.createCourse(courseData)
        this.$emit('create', res)
        this.$notify({text: 'Course created', type: 'success'})
        this.course = res
        return res
      } catch (error) {
        this.processError(error, {redirectName: this.$ROUTER_NAMES.LOGIN_CRM})
        return Promise.reject(error)
      }
    },

    async sendExam(courseID, data) {
      try {
        let res = await this.svc.createConfig(courseID, data)
        this.$emit('create:exam', res)
        this.$notify({text: 'Exam created', type: 'success'})
      } catch (error) {
        this.processError(error, {redirectName: this.$ROUTER_NAMES.LOGIN_CRM})
        return Promise.reject(error)
      }
    }
  }
}
</script>
